<template>
  <div class="WechatCallBack wrapper">
    <Header></Header>
    <el-main class="mainContent">
      <div class="centerWidth">
        <div class="img">
          <img src="@/assets/jinggao.png" alt="" />
        </div>
        <div class="tip">您暂未绑定账号，请选择手机或邮箱进行绑定</div>
        <div class="button phoneBut" @click="centerDialogVisible1 = true">
          使用手机绑定
        </div>
        <div class="button emailBut" @click="centerDialogVisible2 = true">
          使用邮箱绑定
        </div>
      </div>
    </el-main>
    <el-dialog
      title="手机绑定"
      :visible.sync="centerDialogVisible1"
      width="600px"
      center
      top="35vh"
      @close="phoneBindingClose"
    >
      <div class="form">
        <el-input
          placeholder="请输入手机号"
          v-model="phoneBinding.phone"
        ></el-input>
        <div class="flex">
          <el-input
            placeholder="请输入验证码"
            v-model="phoneBinding.code"
          ></el-input>
          <el-button
            type="text"
            :disabled="disabled1"
            class="setCode"
            @click="getCodePhone()"
            >{{ setCodePhone }}</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="submit" @click="PhoneBindingBtn">确定</div>
        <!-- <el-button type="primary" @click="centerDialogVisible2 = false"
          >确 定</el-button
        > -->
      </div>
    </el-dialog>
    <el-dialog
      title="邮箱绑定"
      :visible.sync="centerDialogVisible2"
      width="600px"
      center
      top="35vh"
      @close="eamilBindingClose"
    >
      <div class="form">
        <el-input
          placeholder="请输入邮箱"
          v-model="eamilBinding.email"
        ></el-input>
        <div class="flex">
          <el-input
            placeholder="请输入验证码"
            v-model="eamilBinding.code"
          ></el-input>
          <el-button
            type="text"
            :disabled="disabled2"
            class="setCode"
            @click="getCodeEmail()"
            >{{ setCodeEmail }}</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="submit" @click="EmailBindingBtn">确定</div>
        <!-- <el-button type="primary" @click="centerDialogVisible2 = false"
          >确 定</el-button
        > -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { emailCode, phoneCode, wxLogin } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "WechatCallback",
  components: { Header },
  data() {
    return {
      centerDialogVisible1: false,
      centerDialogVisible2: false,
      phoneBinding: {
        phone: "",
        code: "",
      },
      eamilBinding: {
        email: "",
        code: "",
      },
      setCodePhone: "发送验证码",
      disabled1: false,
      setCodeEmail: "发送验证码",
      disabled2: false,
      code: "",
    };
  },
  watch: {},
  computed: {},
  created() {
    this.unionid = this.$route.query.unionid || "";
    // this.wxLogin()
    if (this.$store.state.access_token) {
      this.user = this.$store.state.user;
    }
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    //获取验证码 并只验证手机号 是否正确
    getCodePhone() {
      if (!this.phoneBinding.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      const reg = /^\d{6,13}$/;
      if (!reg.test(this.phoneBinding.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      let data = {
        phone: this.phoneBinding.phone,
        event: "wechatbind",
      };
      phoneCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtn1(); //验证码倒数60秒
      });
    },
    tackBtn1() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodePhone = "获取验证码";
          this.disabled1 = false;
        } else {
          this.disabled1 = true;
          this.setCodePhone = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    getCodeEmail() {
      if (!this.eamilBinding.email) {
        this.$message.error("请输入邮箱");
        return false;
      }
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!reg.test(this.eamilBinding.email)) {
        this.$message.error("请输入正确的邮箱");
        return false;
      }
      let data = {
        email: this.eamilBinding.email,
        event: "wechatbind",
      };
      emailCode(data).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.tackBtn2(); //验证码倒数60秒
      });
    },
    tackBtn2() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEmail = "获取验证码";
          this.disabled2 = false;
        } else {
          this.disabled2 = true;
          this.setCodeEmail = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    PhoneBindingBtn() {
      if (!this.phoneBinding.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      const reg = /^\d{6,13}$/;
      if (!reg.test(this.phoneBinding.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      if (!this.phoneBinding.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      let data = {
        code: this.phoneBinding.code,
        type: 1,
        username: this.phoneBinding.phone,
        wxCode: this.unionid,
      };
      wxLogin(data)
        .then((res) => {
          if (res.data.status == 0) {
            localStorage.setItem("access_token", res.data.token);
            this.$store.state.access_token = res.data.token;
            localStorage.setItem("typeInfo", res.data.type);
            this.$store.state.typeInfo = res.data.type;
            localStorage.setItem("userId", res.data.userId);
            this.$store.state.userId = res.data.userId;
            let type = res.data.type;
            this.$message({
              message: "绑定成功！", //提示的信息
              type: "success", //类型是成功
              duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
              onClose: () => {
                //跳转页面或执行方法
                if (type == 0) {
                  this.$router.push({ name: "BasicInformation" });
                } else {
                  this.$router.push("/");
                }
              },
            });
          } else if (res.data.status == 1) {
            this.$message({
              message: "暂未注册，立即前往注册！", //提示的信息
              type: "error", //类型是成功
              duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
              onClose: () => {
                //跳转页面或执行方法
                this.$router.push("/Register");
              },
            });
          } else if (res.data.status == 2) {
            this.$message({
              message: "该用户已停用，请联系管理员！", //提示的信息
              type: "error", //类型是成功
              duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
              onClose: () => {
                //跳转页面或执行方法
              },
            });
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
    EmailBindingBtn() {
      if (!this.eamilBinding.email) {
        this.$message.error("请输入邮箱");
        return false;
      }
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!reg.test(this.eamilBinding.email)) {
        this.$message.error("请输入正确的邮箱");
        return false;
      }
      if (!this.eamilBinding.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      let data = {
        code: this.eamilBinding.code,
        type: 2,
        username: this.eamilBinding.email,
        wxCode: this.unionid,
      };
      wxLogin(data).then((res) => {
        if (res.data.status == 0) {
          localStorage.setItem("access_token", res.data.token);
          this.$store.state.access_token = res.data.token;
          localStorage.setItem("typeInfo", res.data.type);
          this.$store.state.typeInfo = res.data.type;
          localStorage.setItem("userId", res.data.userId);
          this.$store.state.userId = res.data.userId;
          let type = res.data.type;
          this.$message({
            message: "绑定成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              if (type == 0) {
                this.$router.push({ name: "BasicInformation" });
              } else {
                this.$router.push("/");
              }
            },
          });
        } else if (res.data.status == 1) {
          this.$message({
            message: "暂未注册，立即前往注册！", //提示的信息
            type: "error", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/Register");
            },
          });
        } else if (res.data.status == 2) {
          this.$message({
            message: "该用户已停用，请联系管理员！", //提示的信息
            type: "error", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
            },
          });
        }
      });
    },
    phoneBindingClose() {
      this.centerDialogVisible1 = false;
      this.phoneBinding.phone = "";
      this.phoneBinding.code = "";
    },
    eamilBindingClose() {
      this.centerDialogVisible2 = false;
      this.eamilBinding.email = "";
      this.eamilBinding.code = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.WechatCallBack {
  .mainContent {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    .centerWidth {
      width: fit-content;
      height: fit-content;
      .img {
        width: 200px;
        margin: 0 auto;
      }
      > div {
        text-align: center;
      }
      .tip {
        margin: 30px auto;
        color: #333;
        font-weight: 700;
      }

      .button {
        display: block;
        outline: 0;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        width: 200px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        margin: 0 auto;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
  .form {
    margin: 0 30px;
    > div {
      margin-top: 20px;
    }
    .el-input {
      width: 100%;
      height: 46px;
      line-height: 46px;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      color: #666;
    }
    .el-input:place-holder {
      color: #666;
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ::v-deep {
        .setCode {
          width: 120px;
          height: 46px;
          // line-height: 50px;
          text-align: center;
          border-radius: 4px;
          background: var(--main-color);
          color: #fff;
          font-size: 14px;
          margin-left: 15px;
        }
      }

      .el-input {
        flex: 1;
      }
    }
  }
  .submit {
    margin: 0 30px;
    margin-bottom: 30px;
    border: none;
    text-transform: uppercase;
    color: #ffffff;
    height: 46px;
    line-height: 46px;
    background: #be0022;
    border-radius: 23px;
    cursor: pointer;
  }
  ::v-deep {
    .el-dialog__title {
      font-weight: bolder;
    }
    .el-input__inner {
      // height: 100%;
      line-height: 50px;
    }
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #dcdfe6;
    }
    .dialog-footer {
      display: block;
    }
    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: var(--main-color);
    }
  }
}

input {
  border: none;
  outline: none;
  color: #666;
}
</style>

